@import '../colors.scss';

.mat-menu-panel.mat-menu-32 {
  min-height: unset;

  .mat-menu-content .mat-menu-item {
    height: 32px;
    line-height: 32px;
  }
}

.mat-menu-panel.mat-menu-flat {
  box-shadow: none;
}

.mat-menu-panel.mat-menu-toolbar {
  background-color: $color-text-toolbar-background;

  .mat-menu-content {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .mat-menu-item {
    color: $color-text-toolbar-items-option;
    font-size: 12px;
  }

  .mat-menu-item:hover {
    background-color: $color-text-toolbar-hover;
  }
}

.mat-menu-panel.mat-menu-toolbar-colors {
  .mat-menu-item {
    line-height: 20px;
    height: 20px;
    margin: 4px;
    padding: 0;
    width: calc(100% - 8px);
  }
}

.mat-menu-panel.mat-menu-toolbar-fonts {
  min-height: 0;

  .mat-menu-item {
    line-height: 100%;
    height: 100%;
    padding: 8px;
    min-width: 240px;
  }
}

.mat-menu-panel.mat-menu-dark {
  background: $color-sidebar-button-dark;

  .mat-menu-content {
    padding: 10px;
  }

  .mat-menu-item {
    border-radius: 50px;
    line-height: 40px;
    height: 40px;
    transition: background-color 150ms ease-in-out;
    color: white;

    &:not(:last-child) {
      margin-bottom: 5px;
    }
  }

  .mat-icon {
    margin-right: 10px;
  }

  .mat-menu-item:hover:not([disabled]),
  .mat-menu-item.cdk-program-focused:not([disabled]),
  .mat-menu-item.cdk-keyboard-focused:not([disabled]),
  .mat-menu-item-highlighted:not([disabled]) {
    background: $color-card-background-dark;
  }
}

.mat-menu-panel.mat-menu-primary-hover {
  .mat-menu-content .mat-menu-item {
    border-left: solid 4px transparent;
    border-right: solid 4px transparent;

    &:hover {
      color: $color-primary;
      border-left-color: $color-primary;
    }
  }
}

.mat-menu-panel.mat-menu-font-08 {
  .mat-menu-content .mat-menu-item {
    font-size: 0.8rem;
  }
}
