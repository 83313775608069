@use '~@angular/material' as mat;
@import url('https://fonts.googleapis.com/icon?family=Material+Icons');
@import './colors.scss';

mat.$blue-palette: (
  50 : #e0edff,
  100 : #b3d1ff,
  200 : #80b2ff,
  300 : #4d93ff,
  400 : #267cff,
  500 : #0065ff,
  600 : #005dff,
  700 : #0053ff,
  800 : #0049ff,
  900 : #0037ff,
  A100 : #ffffff,
  A200 : #f2f4ff,
  A400 : #bfc9ff,
  A700 : #a6b4ff,
  contrast: (
      50 : #000000,
      100 : #000000,
      200 : #000000,
      300 : #000000,
      400 : #ffffff,
      500 : #ffffff,
      600 : #ffffff,
      700 : #ffffff,
      800 : #ffffff,
      900 : #ffffff,
      A100 : #000000,
      A200 : #000000,
      A400 : #000000,
      A700 : #000000,
  )
);

$mat-black: (
  50: #e5e5e6,
  100: #bebec1,
  200: #929397,
  300: #66686d,
  400: #46474e,
  500: #25272f,
  600: #21232a,
  700: #1b1d23,
  800: #16171d,
  900: #0d0e12,
  A100: #5858ff,
  A200: #2525ff,
  A400: #0000f1,
  A700: #0000d7,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #ffffff,
    400: #ffffff,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #ffffff,
    A200: #ffffff,
    A400: #ffffff,
    A700: #ffffff,
  ),
);

/* Dark Theme only, for now */
$dark-primary: mat.define-palette(mat.$blue-palette);
$dark-accent: mat.define-palette($mat-black);
$dark-warn: mat.define-palette(mat.$red-palette);
$light-theme: mat.define-light-theme(
  $primary: $dark-primary,
  $accent: $dark-accent,
  $warn: $dark-warn,
);

$typography: mat.define-typography-config(
  $font-family: 'Inter, sans-serif',
  $button: mat.define-typography-level(16px, 24px, 300),
);

/* Theme-related classes & mixins */
.success {
  color: $color-success;
}

.highlight {
  color: $color-highlight;
}

.f-light {
  font-weight: 300;
}

.f-normal {
  font-weight: 400;
}

.f-medium {
  font-weight: 500;
}

.f-light-12 {
  @extend .f-light;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0;
}

.f-light-14 {
  @extend .f-light;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: -0.4px;
}

.f-normal-14 {
  @extend .f-normal;
  font-size: 14px;
  line-height: 1.2;
  letter-spacing: 0;
}

.f-normal-16 {
  @extend .f-normal;
  font-size: 16px;
  line-height: 1.2;
  letter-spacing: 0;
}

.f-light-19 {
  @extend .f-light;
  font-size: 19px;
  line-height: 26px;
  letter-spacing: 0;
}

.f-light-34 {
  @extend .f-light;
  font-size: 34px;
  line-height: 34px;
  letter-spacing: 0;
}

.f-medium-10 {
  @extend .f-medium;
  font-size: 10px;
  letter-spacing: 0;
  line-height: 12px;
}

.f-medium-15 {
  @extend .f-medium;
  font-size: 15px;
  letter-spacing: 0;
  line-height: 18px;
}

.f-medium-30 {
  @extend .f-medium;
  font-size: 30px;
  letter-spacing: 0;
  line-height: 32px;
}

.text-light-grey {
  color: $color-light-grey;
}

.text-grey {
  color: $color-grey;
}

.text-dark-grey {
  color: $color-background;
}

/* Editor styles */
h1 {
  font-size: 34px;
  font-weight: 300;
}

.verbiage {
  max-width: 450px;
  @extend .wide-verbiage;
}

.wide-verbiage {
  text-align: center;
  line-height: 22px;
  font-weight: 300;
}

button.next-step {
  padding: 12px 28px;
  line-height: unset;
}

button.skip-step {
  font-size: 0.9rem;
}

openreel-wf-editor {
  .mat-button,
  .mat-icon-button,
  .mat-stroked-button,
  .mat-flat-button {
    border-radius: 12px;
    font-size: 14px;
    font-weight: 700;
  }
}

.teleprompter-resize-popup {
  resize: both;
  overflow: hidden;
  border-radius: 4px;
  min-height: 425px;
  min-width: 470px;
  width: 665px;
  height: 551px;
}
