@import '../colors.scss';

openreel-wf-root {
  .mat-card {
    border-radius: 8px;

    &.dark {
      background-color: $color-card-background-dark;
      color: white;
    }

    &.light {
      background-color: white;
      color: black;
    }
  }
}
