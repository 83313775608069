@import '../colors.scss';

.no-p {
  .mat-dialog-container {
    padding: 0;
  }
}

.white-bg {
  .mat-dialog-container {
    background-color: white;
  }
}

.black-fg {
  .mat-dialog-container {
    color: black;
  }
}

.cdk-overlay-backdrop {
  &.blurred {
    backdrop-filter: blur(4px);
  }
}
