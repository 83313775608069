ngx-mat-select-search {
  &.light {
    .mat-select-search-input {
      background-color: white;
      color: black;

      &::placeholder {
        color: gray;
      }
    }
  }

  .mat-select-search-inner {
    .mat-select-search-clear {
      height: 30px;
      width: 30px;
      line-height: 30px;
      display: flex;
      align-items: center;
      justify-content: center;

      .mat-icon {
        height: 20px;
        width: 20px;
        line-height: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 16px;
      }
    }
  }
}
