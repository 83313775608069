@import './../mixins/scrollbars.scss';
@import './../colors.scss';

.mat-select-panel {
  @include customScrollbars($scrollbar-track-color, $scrollbar-thumb-color);

  &.small {
    .mat-option {
      &.contains-mat-select-search {
        height: auto;
        margin-bottom: 4px;
      }

      height: 2em;
      line-height: 2em;
      font-size: 12px;
    }

    .mat-optgroup {
      .mat-optgroup-label {
        height: 2em;
        line-height: 2em;
        font-size: 12px;
      }
    }
  }

  &.light {
    @include customScrollbars(
      $scrollbar-light-track-color,
      $scrollbar-light-thumb-color
    );
    background-color: white;

    .mat-optgroup {
      .mat-optgroup-label {
        color: black;
      }
    }

    .mat-option {
      color: black;
    }
  }
}
