@import '../colors.scss';

.mat-button-toggle-group.pill-toggle {
  background-color: $color-button-toggle-bg-light;
  border-radius: 50px;

  border: none;

  .mat-button-toggle {
    background-color: $color-button-toggle-bg-light;
    border: 3px $color-button-toggle-bg-light solid;
    border-radius: 50%;
    overflow: hidden;

    .mat-button-toggle-button {
      background-color: $color-button-toggle-bg-light;
    }
  }

  .mat-button-toggle-checked {
    background-color: $color-button-toggle-bg-light;

    .mat-button-toggle-button {
      background-color: $color-button-toggle-bg-selected-light;

      .mat-button-toggle-label-content mat-icon {
        color: $color-button-toggle-fg-selected-light;
      }
    }
  }

  .mat-button-toggle + .mat-button-toggle {
    border-left: none;
  }

  .mat-button-toggle-label-content {
    line-height: 32px;
    height: 32px;
    width: 32px;
    padding: 0;

    mat-icon {
      height: 20px;
      width: 20px;
      color: $color-button-toggle-fg-light;
    }
  }
}

.mat-button-toggle-group.pill-toggle.dark {
  background-color: $color-button-toggle-bg-dark;

  .mat-button-toggle {
    background-color: $color-button-toggle-bg-dark;
    border: 3px $color-button-toggle-bg-dark solid;

    .mat-button-toggle-button {
      background-color: $color-button-toggle-bg-dark;
    }
  }

  .mat-button-toggle-checked {
    background-color: $color-button-toggle-bg-dark;

    .mat-button-toggle-button {
      background-color: $color-button-toggle-bg-selected-dark;

      .mat-button-toggle-label-content mat-icon {
        color: $color-button-toggle-fg-selected-dark;
      }
    }
  }

  .mat-button-toggle-appearance-standard
    .mat-button-toggle-label-content
    mat-icon {
    color: $color-button-toggle-fg-dark;
  }
}
