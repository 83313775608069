/* Assorted colors */
$color-success: #33ac2e;
$color-error: #d63649;
$color-background: #2e384d;
$color-card-background-dark: #212135;
$color-primary: #0065ff;
$color-highlight: #eab707;
$color-light: #fff;
$color-dark: #000;
$color-grey: #9da6af;
$color-light-grey: #d3d7e1;
$color-text-light: #656e82;

$color-teleprompter-pause: #f92221;

$color-text-toolbar-background: #f7c137;
$color-text-toolbar-hover: #d7a807;
$color-text-toolbar-items-border: #d3d2d0;
$color-text-toolbar-items-option: #202125;
$color-text-toolbar-alignment-active: $color-dark;
$color-text-toolbar-alignment-inactive: #00000033;

$color-no-preview-background: #313138;
$color-text-negative: $color-error;

$color-player-gradient-1: #313138;
$color-player-gradient-2: $color-dark;

$source-color: #72dc0b;

$color-sidebar-button-dark: #16151a;
$color-gray-button: #4d4d4d;
$header-toolbar-background: #d14343;

/* Scrollbars */
$scrollbar-track-color: #25272f;
$scrollbar-thumb-color: #919191;

$scrollbar-light-track-color: #f0f0f0;
$scrollbar-light-thumb-color: #cdcdcd;

$color-progress-gradient-1: #35b7ff;
$color-progress-gradient-2: #358dff;
$color-progress-background: $color-light;
$color-expanded-panel-background: #f4f6fc;

/* Item selection */
$item-select-toolbar-bg-color: #ebebeb;
$item-select-toolbar-action-bg-color: $color-light;
$item-select-toolbar-color: #000;
$item-select-toolbar-color-selected: $color-light;

/* Instant Wizard */
$color-wizard-box-bg: $color-light;
$color-wizard-box-fg: #000000;
$color-wizard-box-button-bg: #2e384d;
$color-wizard-box-button-disabled-bg: #969cab;
$color-wizard-box-expanded-bg: #f4f4f6;
$color-wizard-box-setting-bg: #e3e3e3;
$color-wizard-box-toggle-group-inactive-bg: #f5f5f5;
$color-wizard-box-toggle-group-inactive-fb: #969cab;
$color-setting-button-bg: #eff2ff;
$color-setting-button-disabled-bg: #ced0d9;

/* Button Toggle */
$color-button-toggle-bg-dark: #16151a;
$color-button-toggle-bg-light: #f4f4f6;
$color-button-toggle-bg-selected-light: $color-light;
$color-button-toggle-bg-selected-dark: $color-card-background-dark;
$color-button-toggle-fg-dark: $color-light;
$color-button-toggle-fg-light: #656d81;
$color-button-toggle-fg-selected-light: $color-primary;
$color-button-toggle-fg-selected-dark: $color-primary;

/* Templates */
$color-template-popup-input: #333;
$color-template-popup-input-border: #969cab;
$color-template-popup-input-action-border: $color-primary;
$color-video-container-background: #eff2ff;

/* Takes */
$color-gray-background: #4d4d4d;
$color-white-background: $color-light;

$update-brandkit-bar-background-color: #e8edfa;
$update-brandkit-bar-color: #0162fa;

/* Captions */
$color-alert-red: #dc1a3c;
$color-check-icon: #0066ff;
$color-check-icon-inner-border: #ebf0ff;
$color-check-icon-outer-border: #f3f6ff;
