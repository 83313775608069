$sides: (top, bottom, left, right);

@mixin margins($values...) {
  @each $value in $values {
    .m-#{$value} {
      margin: #{$value}px !important;
    }
    .my-#{$value} {
      margin-top: #{$value}px !important;
      margin-bottom: #{$value}px !important;
    }
    .mx-#{$value} {
      margin-left: #{$value}px !important;
      margin-right: #{$value}px !important;
    }

    @each $side-name in $sides {
      .m-#{$side-name}-#{$value} {
        margin-#{$side-name}: #{$value}px !important;
      }
    }
  }
}

@mixin paddings($values...) {
  @each $value in $values {
    .p-#{$value} {
      padding: #{$value}px !important;
    }
    .py-#{$value} {
      padding-top: #{$value}px !important;
      padding-bottom: #{$value}px !important;
    }
    .px-#{$value} {
      padding-left: #{$value}px !important;
      padding-right: #{$value}px !important;
    }

    @each $side-name in $sides {
      .p-#{$side-name}-#{$value} {
        padding-#{$side-name}: #{$value}px !important;
      }
    }
  }
}

@mixin positions($values...) {
  @each $value in $values {
    .top-#{$value} {
      top: #{$value}px !important;
    }
    .right-#{$value} {
      right: #{$value}px !important;
    }
    .bottom-#{$value} {
      bottom: #{$value}px !important;
    }
    .left-#{$value} {
      left: #{$value}px !important;
    }
  }
}

@mixin matIcons($values...) {
  @each $value in $values {
    .mat-icon.mat-icon-#{$value} {
      line-height: #{$value}px !important;
      font-size: #{$value}px !important;
      height: #{$value}px !important;
      width: #{$value}px !important;
    }
  }
}

@mixin matButtons($values...) {
  @each $value in $values {
    button.mat-btn-#{$value} {
      line-height: #{$value}px !important;
      height: #{$value}px !important;
      width: #{$value}px !important;
    }
  }
}
