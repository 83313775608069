@import './../colors.scss';

.input-bg-white {
  .mat-form-field-wrapper {
    padding-bottom: 0;
  }

  .mat-form-field-subscript-wrapper {
    top: calc(100% - 3px);
  }

  .mat-form-field-outline,
  .mat-form-field-label,
  .mat-select-value,
  .mat-select-value-text,
  .mat-select-arrow,
  .mat-input-element {
    color: $color-dark !important;
  }

  .mat-form-field-invalid {
    .mat-form-field-outline,
    .mat-form-field-label,
    .mat-select-value,
    .mat-select-value-text,
    .mat-select-arrow,
    .mat-input-element {
      color: $color-error !important;
    }
  }
}

mat-form-field.no-padding > .mat-form-field-wrapper {
  padding-bottom: 0;
}

.input-primary-underline {
  .mat-form-field-underline {
    background-color: $color-primary !important;
  }
}

.input-no-underline {
  .mat-form-field-underline {
    background-color: unset !important;
  }
}

.mat-form-field-appearance-outline .mat-form-field-infix {
  border-top: 6px solid transparent
}

mat-form-field.w-full .mat-form-field-infix {
  width: 100%;
}
