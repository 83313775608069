mat-checkbox {
  &.inverse {
    .mat-checkbox-inner-container {
      .mat-checkbox-frame {
        border-color: black;
      }
      .mat-checkbox-checkmark-path {
        stroke: white !important;
      }
    }
  }
}
