.mat-form-field-appearance-outline {
  .mat-select {
    .mat-select-arrow-wrapper {
      transform: unset;
    }
  }
}
.mat-primary .mat-option.mat-selected:not(.mat-option-disabled) {
  color: white !important;
}
