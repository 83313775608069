.mat-button.no-overlay .mat-button-focus-overlay {
  opacity: 0 !important;
}

.mat-flat-button.mat-button-white {
  color: black;
  background-color: white;
}

.mat-button-base {
  &.f-light-14 {
    @extend .f-light-14;
  }

  &.l-height-24 {
    line-height: 24px;
  }

  &.mat-button-primary {
    background-color: $color-primary;
  }

  &.mat-button-dark {
    background-color: $color-gray-button;
    color: white;
  }

  &.mat-button-white {
    color: black;
    background-color: white;
  }

  &.mat-button-pill-rounded {
    border-radius: 999px;
    line-height: 28px;
    height: 28px;
  }

  &.mat-button-pill-sm-round {
    border-radius: 10px;
    line-height: 28px;
    height: 28px;
  }

  &.mat-button-pill-sm {
    border-radius: 5px;
    line-height: 28px;
    height: 28px;
  }

  &.mat-button-pill-md {
    border-radius: 20px;
    line-height: 40px;
    height: 40px;

    .mat-button-wrapper {
      @extend .f-light-14;
    }
  }

  &.mat-button-pill-lg {
    border-radius: 20px;
    line-height: 40px;
    height: 40px;

    .mat-button-wrapper {
      @extend .f-light-14;
    }
  }

  &.t-align-left {
    @extend .t-align-left;
  }

  &.no-shadow {
    @extend .no-shadow;
  }

  &.mat-button-action {
    width: 210px;
    height: 45px;
    @extend .f-light-14;
    color: white;
  }
}

.mat-stroked-button:not([disabled]) {
  &.mat-button-action {
    border-color: #358dff;
  }
}

.mat-button-flex-8 {
  .mat-button-wrapper {
    display: inline-flex;
    align-items: center;
    gap: 8px;
  }
}
