@import './../colors.scss';

openreel-layer-form-preset {
  display: flex;
  flex-direction: column;
  gap: 8px;

  .caption {
    font-size: 20px;
    font-weight: 400;
  }

  .controls-layer {
    display: flex;
    flex-direction: column;
    gap: 8px;
    margin-bottom: 8px;

    .controls-field {
      > * {
        display: block;
        width: 100%;
      }
    }
  }
}
