@use '~@angular/material' as mat;
@import 'styles/import';

/* Material Theme */
@include mat.core($typography);
@include mat.all-component-themes($light-theme);

/* Utilities */
// prettier-ignore
@include margins(0, 2, 4, 6, 8, 12, 14, 16, 20, 24, 26, 28, 30, 32, 36, 38, 40, 46, 48, 56, 60, 80, 100, 200);
// prettier-ignore
@include paddings(0, 2, 4, 6, 8, 12, 16, 20, 24, 28, 32, 36, 40, 46, 48, 56, 60, 80, 100);
// prettier-ignore
@include positions(8, 12, 32, 46);

@include matIcons(12, 14, 16, 18, 20, 22, 24, 32, 34, 36, 40);
@include matButtons(14, 20, 22, 24, 34);

@import '~ngx-toastr/toastr.css';

/* General Styles */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html,
body {
  height: 100%;
  font-size: 16px;
  font-family: Rubik, monospace;
}

body {
  background-color: #eff2ff;
  color: black;
}

input,
button {
  font-family: Rubik, monospace;
}

.shadow-2-4 {
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
}

.no-shadow {
  box-shadow: none;
}

[hidden] {
  display: none !important;
}

.block {
  display: block;
}

.inline-block {
  display: inline-block;
}

.inline-flex {
  display: inline-flex;
}

/* Flex */
.flex {
  display: flex;
}

.flex-col {
  display: flex;
  flex-direction: column;
}

.flex-auto {
  flex: auto;
}

.flex-none {
  flex: none;
}

.flex-wrap {
  flex-wrap: wrap;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse;
}

.flex-nowrap {
  flex-wrap: nowrap;
}

.flex-align-start {
  align-items: flex-start;
}

.flex-align-center {
  align-items: center;
}

.flex-align-end {
  align-items: flex-end;
}

.flex-justify-start {
  justify-content: flex-start;
}

.flex-justify-end {
  justify-content: flex-end;
}

.flex-justify-center {
  justify-content: center;
}

.flex-justify-between {
  justify-content: space-between;
}

.flex-justify-items-center {
  justify-items: center;
}

.flex-justify-evenly {
  justify-content: space-evenly;
}

.flex-grow-1 {
  flex-grow: 1;
}

/* Borders */
.b-radius-4 {
  border-radius: 4px;
}

.b-radius-8 {
  border-radius: 8px;
}

/* Grid */
.grid {
  display: grid;
}

.gap-8 {
  gap: 8px;
}

.gap-16 {
  gap: 16px;
}

/* Sizing */
.w-full {
  width: 100%;
}

.h-full {
  height: 100%;
}

.mh-40 {
  max-height: 40px;
}

.w-max-full {
  max-width: 100%;
}

.w-min-unset {
  min-width: unset !important;
}

.w-min-0 {
  min-width: 0px !important;
}

.w-min-30 {
  min-width: 30px !important;
}

.w-min-60 {
  min-width: 60px !important;
}

.w-min-200 {
  min-width: 200px !important;
}

.h-min-0 {
  min-height: 0px !important;
}

.h-24 {
  height: 24px;
}

.h-40 {
  height: 40px;
}

.h-44 {
  height: 44px;
}

.h-48 {
  height: 48px;
}

.h-56 {
  height: 56px;
}

.w-120 {
  width: 120px;
}

.line-height-36 {
  line-height: 36px;
}

/* Position */
.relative {
  position: relative !important;
}

.absolute {
  position: absolute !important;
}

.absolute-center-h {
  left: 50%;
  transform: translate(-50%, 0);
}

.inset-0 {
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
}

.z-0 {
  z-index: 0;
}

.z-1 {
  z-index: 1;
}

.z-10 {
  z-index: 10;
}

.z-20 {
  z-index: 10;
}

/* Text */
.text-10 {
  font-size: 10px;
}

.text-11 {
  font-size: 11px;
}

.text-12 {
  font-size: 12px;
}

.text-13 {
  font-size: 13px;
}

.text-14 {
  font-size: 14px;
}

.text-28 {
  font-size: 28px;
}

.text-32 {
  font-size: 32px;
}

.text-34 {
  font-size: 34px;
}

.text-weight-300 {
  font-weight: 300;
}

.text-weight-400 {
  font-weight: 400;
}

.text-weight-500 {
  font-weight: 500;
}

.text-weight-700 {
  font-weight: 700;
}

.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.select-none {
  user-select: none;
}

.select-text {
  user-select: text;
}

.select-all {
  user-select: all;
}

.select-auto {
  user-select: auto;
}

/* Text colors */
.text-color-primary {
  color: $color-primary !important;
}

.text-color-negative {
  color: $color-text-negative !important;
}

.text-color-light {
  color: $color-light !important;
}
.text-color-dark {
  color: $color-dark !important;
}

/* Material Icons */

.mat-icon.text-color-white {
  path {
    fill: white !important;
  }
}

.mat-icon.text-color-primary {
  path {
    fill: $color-primary !important;
  }
}

.mat-icon.text-color-error {
  path {
    fill: $color-error !important;
  }
}

/* Cursor */
.cursor-pointer {
  cursor: pointer;
}

.cursor-move {
  cursor: move;
}

/* Overflow */
.overflow-hidden {
  overflow: hidden;
}

.overflow-y-auto {
  overflow-y: auto;
}

.overflow-y-scroll {
  overflow-y: scroll;
}

.overflow-y-hidden {
  overflow-y: hidden;
}

.overflow-x-hidden {
  overflow-x: hidden;
}

/* Opacity */
.opacity-0 {
  opacity: 0;
}

.opacity-100 {
  opacity: 1;
}

.mat-progress-bar {
  .mat-progress-bar-buffer {
    background-color: rgba(0, 0, 0, 0.5);
  }

  .mat-progress-bar-background {
    display: none;
  }
}
