@import './colors.scss';

.wizard-box {
  background: $color-wizard-box-bg;
  color: $color-wizard-box-fg;
  border-radius: 10px;
  box-shadow: rgb(0 0 0 / 10%) 0px 2px 10px 0px;
  padding: 20px;
}

button.wizard-small-button {
  cursor: pointer;
  border-radius: 4px;
  color: $color-primary;
  background-color: $color-setting-button-bg;
  font-size: 12px;
  line-height: 16px;
  border: none;
  padding: 4px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;

  &.mat-icon-button {
    width: 24px;
    height: 24px;

    .mat-icon {
      max-width: 16px;
      max-height: 16px;
      line-height: 16px;
      vertical-align: baseline;
    }
  }

  &:disabled {
    color: gray;
    background-color: $color-setting-button-disabled-bg;
    cursor: default;
  }
}
