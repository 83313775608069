@import '../colors.scss';

mat-tab-group {
  &.tab-pill {
    .mat-tab-header {
      background-color: $color-background;
      border-radius: 20px;
      height: 40px;
      border-bottom: none;
    }

    .mat-tab-label-content {
      font-size: 14px;
      line-height: 17px;

      .mat-icon {
        margin-right: 5px;
      }
    }

    mat-ink-bar {
      display: none;
    }

    .mat-tab-label {
      height: 40px;
      border-radius: 20px;
      opacity: 1;
    }

    .mat-tab-label-active {
      background-color: $color-primary;
    }

    .mat-tab-body-content {
      padding-top: 24px;
    }
  }
}
