@import '../colors.scss';

openreel-wf-project {
  .mat-expansion-panel-content {
    background-color: $color-expanded-panel-background;
    .mat-expansion-panel-body {
      padding: 15px 15px 0px 15px;
    }
  }

  .mat-expansion-indicator:after {
    color: inherit;
  }
}

openreel-wf-recordings-list {
  .mat-expansion-panel-content {
    .mat-expansion-panel-body {
      padding: 15px 15px 15px;
      background-color: $color-light !important;
    }
  }
}
