@import './../colors.scss';

@mixin customScrollbars($colorTrack, $colorThumb) {
  &::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }

  &::-webkit-scrollbar-track {
    background: $colorTrack;
  }

  &::-webkit-scrollbar-thumb {
    background: $colorThumb;
    border-radius: 3px;
  }
}
